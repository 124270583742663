<template>
  <div>
    <b-modal v-model="messageSuccessTarifCreditUpdate.status">
      <div class="d-block text-center">
        <h4 class="text-success">
          {{ messageSuccessTarifCreditUpdate.message }}
        </h4>
      </div>
      <template #modal-footer>
        <button
          v-b-modal.modal-close_visit
          class="btn btn-danger btn-sm m-1"
          type="submit"
          @click.prevent="closeModale"
        >
          Fermer
        </button>
      </template>

    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    infoModale: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      modalShow: false,
      info: {
        credit: null,
      },
    }
  },
  computed: {
    messageSuccessTarifCreditUpdate() {
      return this.$store.getters['user/getMessageUpdateTarifCredit']
    },
  },
  methods: {
    closeModale() {
      this.messageSuccessTarifCreditUpdate.status = false
    },
  },
}
</script>

<style scoped>
 .modal-content,.modale{
    margin-top:500px !important;
 }
</style>
